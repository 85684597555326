<template>
	<div class="mt-lg-4">
		<div v-if="!addOrEditReady">
			<LoadingSpinner class="col-12" />
		</div>
		<div v-else>
			<div class="row align-items-center">
				<div class="col">
					<h3>1. {{ $t('pax.mes_juments') }}</h3>
				</div>
				<div class="col-auto">
					<div class="input-group stallion_search">
					    <button class="input-group-text px-3" @click="openIntroduction"><font-awesome-icon :icon="['fas', 'info']" /></button>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col">
					<div v-if="pax_suggestion" class="paragraphes">{{ $t('pax.selectionner_une_jument') }}</div>
					<div v-else class="paragraphes">{{ $t('pax.text_juments_infos') }}</div>
				</div>
			</div>
			<div class="row stallions-list">
				<div class="col-6" v-for="mare in mares" :key="mare.horse_id" @click="goToFicheMare(mare.horse_id)">
					<div class="stallion">{{ mare.horse_nom }}</div>
				</div>
			</div>
		</div>

		<b-modal dialog-class="modal-pax" ref="modal" hide-footer>
			<template v-slot:modal-title>
				{{ $t('pax.suggestion_pax') }}
			</template>

			<div v-html="text_intro"></div>
		</b-modal>
	</div>
</template>

<script type="text/javascript">
	import Vue from 'vue'
    import { EventBus } from 'EventBus'
	import Navigation from "@/mixins/Navigation.js"
	import PaxMixin from "@/mixins/Pax.js"
	import _cloneDeep from 'lodash/cloneDeep';
	import _remove from 'lodash/remove';
	import Window from '@/mixins/Window.js'

	export default {
		name: "Mares",
		props: {
			mares_selected: {
				type: Array,
				default: () => ( [] )
			},
			step_label: {
				type: String,
				default: () => null
			},
			pax_suggestion: {
				type: Boolean,
				default: () => false
			}
		},
		mixins: [Navigation, PaxMixin, Window],
		data () {
			return {
				addOrEditReady: false,
				search: '',
				mares: [],
				text_intro: '',
				mares_selected_local: []
			}
		},
		created() {
			this.init_component()
		},
		methods: {
			async init_component() {
				this.text_intro = this.getConfig('translations')['pax_reverse'][Vue.i18n.locale()]
				this.addOrEditReady = false

				const country = this.getConfig('country')
				if(!country) {
					this.$emit("update:step_label", "country")
					return false
				}

				this.mares = await this.getMares()
				
				this.addOrEditReady = true
			},

			goToFicheMare(mare_id) {
				this.$router.push({ name: 'HorsePaxSuggestion', params: { horse_id: mare_id }})
			},

			openIntroduction() {
				this.$refs["modal"].show()
			},

		},
		components: {
			LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
		}
	}
</script>
